import React, {useState, useEffect, useRef} from "react";
import Footer from "./layouts/Footer";
import {WebLink, SiteWide, Images} from "./customComponents";
import Ticker1 from "./customComponents/Ticker1";
import Ticker2 from "./customComponents/Ticker2";

import {systemColor} from '../utility/styles';

import Slider from "react-slick";
import ModalCustomHead from "./customComponents/ModalCustomHead";
import Colors from '../utility/Colors';
import {homepage, contact_us} from '../utility/APIRequests';
import MobileBreaker from "./layouts/MobileBreaker";
import LoadingOverlay from 'react-loading-overlay';

import { useSelector, useDispatch } from 'react-redux';
import { exchangeSelect, footballSelect, allTradeSelect, allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import {footballActions, authActions, withdrawalActions, depositActions, tradeActions} from '../redux/actions';

import AutoNotification from "./layouts/AutoNotification";
import Helper1, {byNextDate, niceDate, formatTime, formatDate, displayTime, notify, styles, money, FormatOnly, customNormalModalStyles, moneyStatus} from '../utility/Helper1';
import { useHistory } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { RiCheckboxIndeterminateLine, RiMenuLine, RiCheckboxLine } from "react-icons/ri";
import { AiFillSliders, AiOutlineFieldTime, AiOutlineComment, AiOutlineMail } from "react-icons/ai";
import { FaStar, FaChevronRight, FaSun, FaCloudMoon } from "react-icons/fa";
import Cookies from 'js-cookie'

import {FcBookmark, FcBriefcase, FcBrokenLink, FcUpload, FcBusinessContact, FcCableRelease, FcDiploma2} from "react-icons/fc";
// import Ticker from 'react-ticker'
import Marquee from 'react-css-marquee'

import EachFixture from "./Football/EachFixture"

import BetModalPlace from './Football/BetModalPlace';
import parse from 'html-react-parser';


import { AiFillAlert, AiFillWechat, AiFillApi, AiFillAppstore, AiFillBook, AiFillBuild, AiFillBulb } from "react-icons/ai";

// import { HiOutlineChartPie, HiOutlineCalculator, HiOutlineCalendarDays, HiOutlineCalendar, HiOutlineCamera, HiOutlineChartBarSquare, HiOutlineChartBar } from "react-icons/hi2";

import CustomAgo from "./customComponents/CustomAgo";

import Modal from 'react-modal';
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import Header from './layouts/Header'

import NewsTicker from "./customComponents/Ticker/NewsTicker";
import { PuffLoader } from 'react-spinners';
import SocialMediaIcons from './SocialMediaIcons';


const nextMatch = {
    slidesToShow: 1.2,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
}




function Home() {



    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);

    const darkVal = (Cookies.get('darkMode') ?? 1)

    const [isDarkMode, setisDarkMode] = useState(darkVal) //dark mode by default

    const history = useHistory();
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const exchangeStoreData = useSelector(exchangeSelect);
    const tradeStoreData    = useSelector(allTradeSelect);
    const footballData      = useSelector(footballSelect);

    const [openModal, setOpenModal]   = useState(false);
    const [whichModal, setWhichModal]   = useState(1);
    const [loadingStat, setLoadingStat]   = useState(true);

    const [withdrawAmount, setWithdrawAmount]   = useState('');
    const [withdrawalPin, setWithdrawalPin]     = useState('');
    const [walletAddress, setWalletAddress]     = useState('');
    const [whichWithdrawal, setWhichWithdrawal]     = useState(0);

    const [selectedMotherCurrencyForCalculator, setSelectedMotherCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.mother);
    const [selectedPlanCurrencyForCalculator, setSelectedPlanCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.childs);
    const [calculatorInput, setCalculatorInput]     = useState(0);
    const [calculatorResult, setCalculatorResult]     = useState(0);

    const allDepositData = useSelector(allDepositSelect);
    const [depositAmount, setDepositAmount]   = useState('');
    const [selectedCurrency, setSelectedCurrency]   = useState('');
    const [selectedCurrencyObj, setSelectedCurrencyObj]   = useState('');

    const [recentBets, setRecentBets]   = useState([]);
    const [recentBetsLoading, setRecentBetsLoading]   = useState(true);


    const [receiverEmail, setReceiverEmail]     = useState('');
    const [sendingAmount, setSendingAmount]     = useState('');


    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');

    const [tradeAmount, setTradeAmount]   = useState('');
    const [homeFixture, setHomeFixture]   = useState(byNextDate(footballData?.fixtures));

    const [selectedPlan, setSelectedPlan]   = useState(site_settings?.plans?.[0]?.childs?.[0]);
    
    const balanceref2 = useRef();
    const balanceref1 = useRef();
    const chooseCurrency1 = useRef();
    const chooseCurrency2 = useRef();

    const systemColorManager = systemColor()
    const [betModal, setBetModal]          = React.useState(false);
    const [selectedOddObj, setSelectedOddObj]          = React.useState({});


    const dispatch = useDispatch();

    const ref = useRef();

    
    useEffect(() => {
        setHomeFixture(byNextDate(footballData?.fixtures));
    }, [footballData?.fixtures])


    useEffect(() => {
        loadRecentBets()
    }, [])

    const loadRecentBets = () => {

        if((site_settings?.football_settings?.show_recent_bets_on_homepage ?? 0) == 0){ return; }

        setRecentBetsLoading(true)
        dispatch(footballActions.onGetRecents("football/home-recent", response => {

            setRecentBetsLoading(false)

        }))

    }


    const changeToDarkMode = (booleanVal) => {
        Cookies.set('darkMode', booleanVal, { secure: true, sameSite: 'Lax', expires: 7})
        setisDarkMode(booleanVal)
    }



    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ setLoadingStat(false); return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {
            setLoadingStat(false)
        }))

    }

    useEffect(() => {

        let timing = setTimeout(() => {
            loadAllHomePageData();
        }, 2000)
        
        return () => clearTimeout(timing);
    }, [])



    
    



    // main slider
    const settings = {
        fade: true,
        dots: false,
        infinite: true,
        speed: 1000,
        cssEase: "ease-in-out",
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },,
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };



    const eachNewsClicked = (item) => {
        history.push(`/news?id=${item.id}`)
    }




    const eachIsClicked = (eachFixture) => {

        if(!userData?.accountId){
            history.push(`signin`)
            return;
        }
        setSelectedOddObj(eachFixture)
        setBetModal(true)
    }



    

    return (

        <SiteWide overlayText={loadingText} loading={loading}>                


                <div className="row no-gutter" style={{margin: 0}}>

                    <div className="col-12">
                        <Slider className="main-home-slider" arrows={false} {...settings}>
                            {site_settings?.slider_settings?.map((item, index) => (
                                <div style={{}} className="">
                                    <a target="_blank" href={item?.linkWhenClicked}>
                                        <Images style={{}} resizeMode="contain" height="200" width="100%" source={item?.image} />
                                    </a>
                                </div>)
                            )}
                        </Slider>
                    </div>
                    {/*<div className="col-3">
                        <Images style={{borderRadius: 8, marginLeft: 8}} resizeMode="contain" height="200" width="100%" source={require('../images/8856a07e6068bcc9f920b5e05358bb26.png')} />
                    </div>*/}

                </div>







                    {site_settings?.operational_settings?.ticker_news_on_home_allowed == 1 && <NewsTicker eachNewsClicked = {eachNewsClicked} title={site_settings?.operational_settings?.ticker_news_home_title} newsList={site_settings.news}/>}


                    <div className="" style={{marginTop: 12, ...systemColorManager.txt_1}}>
                        <Images height={15} style={{marginRight: 5}} source={require('../images/football.png')} alt={'ball'} />
                        Upcoming Matches
                    </div>





                    <div onClick={() => history.push(`fixture/${homeFixture?.[0]?.fixture_id}`)} style={{borderRadius: 10, minHeight: 20, ...systemColorManager.bg_2, padding: 18, marginTop: 9, alignItems: 'center'}} className="flexify cursor-pointer">

                        {homeFixture?.[0]?.teams_home_name && <><div className="col-3 flexify" style={{flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <div className="" style={{fontWeight: 900, flex: 1, textTransform: 'capitalize', display: 'flex'}}>
                                
                                <div className="league-img-circle" style={{...systemColorManager.btn_3}}>
                                    <Images height={30} style={{}} source={homeFixture?.[0]?.teams_home_logo} alt={homeFixture?.[0]?.teams_home_name} />
                                </div>

                                <div className="league-img-circle mgl-10" style={{...systemColorManager.btn_3}}>
                                    <Images height={30} style={{}} source={homeFixture?.[0]?.teams_away_logo} alt={homeFixture?.[0]?.teams_away_name} />
                                </div>

                            </div>
                        </div>

                        <div className="col-3 text-center flexify" style={{...systemColorManager.txt_1}}>
                            {homeFixture?.[0]?.teams_home_name}
                        </div>

                        <div className="col-3">
                            <Images height={25} style={{marginRight: 5}} source={require('../images/vs.png')} alt={'versus'} />
                        </div>

                        <div className="col-3 text-center flexify" style={{...systemColorManager.txt_1}}>
                            {homeFixture?.[0]?.teams_away_name}
                        </div>

                        </>}

                        {!homeFixture?.[0]?.teams_home_name && <div className="text-center">
                            <PuffLoader color="#fff" />
                        </div>}
                        
                    </div>


                    {homeFixture?.[0]?.fixture_datetime && <div className="flexify">

                        <div className="flexify-left" style={{...systemColorManager.txt_1, flex: 1, marginTop: 12, alignItems: 'center'}}>
                            <AiOutlineFieldTime size={20} />
                            &nbsp;
                            {formatTime(homeFixture?.[0]?.fixture_datetime)} {formatDate(homeFixture?.[0]?.fixture_datetime)}
                        </div>


                        <div className="flexify-left" style={{...systemColorManager.txt_1, marginTop: 12, alignItems: 'center'}}>
                            {homeFixture?.[0]?.league_name}
                        </div>

                    </div>}






                    <div className="flexify" style={{marginTop: 18}}>

                        <div className="flexify-left" style={{...systemColorManager.txt_1, flex: 1, marginTop: 18, alignItems: 'center'}}>
                            <AiFillSliders size={12} /> &nbsp; Popular matches
                        </div>


                        <div onClick={() => history.push('/game')} className="flexify-left cursor-pointer" style={{...systemColorManager.txt_1, marginTop: 12, alignItems: 'center'}}>
                            More &nbsp; <FaChevronRight size={12} />
                        </div>

                    </div>








                    <Slider className="popular-matches-slider" arrows={false} {...nextMatch}>

                        {homeFixture?.slice(0, 5)?.map((item) => <EachFixture eachIsClicked={eachIsClicked} history={history} fixture={item} />)}
                      
                    </Slider>



                    <Slider style={{marginTop: 20}} className="popular-matches-slider" arrows={false} {...nextMatch}>

                        {homeFixture?.slice(6, 10)?.map((item) => <EachFixture eachIsClicked={eachIsClicked} history={history} fixture={item} />)}
                      
                    </Slider>









                    <div onClick={() => history.push('/game')} className="text-center cursor-pointer" style={{...systemColorManager.bg_2, ...systemColorManager.txt_1, marginTop: 30, padding: 20}}>
                        All Games
                    </div>













                    {(site_settings?.football_settings?.show_recent_bets_on_homepage ?? 0) == 1 && <div style={{marginTop: 18}}>


                        <div className="flexify" style={{marginTop: 18, marginBottom: 18}}>

                            <div className="flexify-left" style={{...systemColorManager.txt_1, flex: 1, marginTop: 15, alignItems: 'center'}}>
                                <FaStar size={12} /> &nbsp; Recent games
                            </div>

                        </div>


                        <div className="" style={{borderRadius: 10, ...systemColorManager.bg_2}}>

                            <div className="flexify" style={{padding: 20, borderTopLeftRadius: 10}}>
                                
                                <div className="text-center" style={{...systemColorManager.txt_1, flex: 1}}>
                                   Match
                                </div>

                                <div className="text-center" style={{...systemColorManager.txt_1, flex: 1}}>
                                   Amount
                                </div>

                            </div>

                            <div  style={{...systemColorManager.bg_2}}>
                                {footballData?.recent_bets?.map((item) => <div className="flexify" style={{...systemColorManager.txt_1, padding: 20, borderTopLeftRadius: 10}}>
                                    <div className="text-cente" style={{flex: 1, alignItems: 'flex-start', justifyContent: 'flex-start'}}>{`${item.teams_home_name} vs ${item.teams_away_name}`}</div>
                                    <div className="text-cente" style={{}}>{money(item.amount, item.currency)}</div>
                                </div>)}
                            </div>


                        </div>

                    </div>}
                      












                    <div style={{marginTop: 28, marginBottom: 28}}>

                        <div className="" style={{borderRadius: 10, ...systemColorManager.bg_2}}>

                            <div className="text-center" style={{padding: 20}}>
                                <WebLink to={'/'} className="logo text-center">
                                    <Images height="40px" src={(site_settings?.site_settings?.logo)} alt="logo" />
                                </WebLink>
                            </div>



                            <div  style={{ padding: 20}}>
                                <p style={{...systemColorManager.txt_3}}>
                                    {parse(site_settings?.content_settings?.[0]?.con3 ?? '')}
                                </p>
                            </div>



                            <div className="text-center flexify" style={{marginTop: 10, padding: 20}}>
                                
                                {1>2 && <button style={{...systemColorManager.btn_2, ...systemColorManager.txt_1, borderRadius: 4, padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <AiOutlineMail size={20} /> &nbsp;Support
                                </button>}

                                {((site_settings?.site_settings?.livechat_show_button ?? '') == 1) && <button onClick={() => history.push('customer-care')} style={{...systemColorManager.btn_2, ...systemColorManager.txt_1, marginLeft: 15, borderRadius: 4, padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <AiOutlineComment size={20} /> &nbsp;Live chat
                                </button>}

                                <div style={{...systemColorManager.bg_2, display: 'flex', borderRadius: 4, padding: 5, marginLeft: 15}}>
                                    <button onClick={() => changeToDarkMode(0)} style={{...(isDarkMode == 0?systemColorManager.btn_2:{}), ...(isDarkMode == 0?systemColorManager.txt_1:systemColorManager.txt_3), borderTopLeftRadius: 4, borderBottomLeftRadius: 4, padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <FaSun size={16} />
                                    </button>
                                    <button onClick={() => changeToDarkMode(1)} style={{...(isDarkMode == 1?systemColorManager.btn_2:{}), ...(isDarkMode == 1?systemColorManager.txt_1:systemColorManager.txt_3), borderTopRightRadius: 4, borderBottomRightRadius: 4, padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <FaCloudMoon size={16} color="" />
                                    </button>
                                </div>

                            </div>



                            {/*<div className="flexify" style={{marginTop: 30, padding: 5, paddingBottom: 20}}>
                                <div className="text-center" style={{flex: 1}}>About us</div>
                                <div className="text-center" style={{flex: 1}}>Privacy</div>
                                <div className="text-center" style={{flex: 1}}>Terms and condition</div>
                            </div>*/}

                            <div className="flexify" style={{marginTop: 10, padding: 5, paddingBottom: 20, ...systemColorManager.txt_3}}>
                                <div className="text-center" style={{flex: 1}}>Copyright &copy; {(new Date).getFullYear()} - All right reserved</div>
                            </div>

                           


                        </div>



                    </div>
                      

                     <SocialMediaIcons/>

                    {(site_settings?.site_settings?.showAccreditation ?? 0) == 1 && <div className="flexify" style={{marginTop: 30, padding: 5, paddingBottom: 20, ...systemColorManager.txt_3}}>
                        <div className="text-center" style={{flex: 1}}>Programmed by <a href="mailto:hyiptonya@protonmail.com">Tonya Republical</a></div>
                    </div>}


                    {userData?.accountId && <BetModalPlace setBettingInAction = {setloading} setBettingInActionText={setLoadingText} betModal={betModal} setBetModal={setBetModal} oddObjSelected = {selectedOddObj} />}

        </SiteWide>
    );
}

export default Home;