import React, {Component, useState} from "react";
import {WebLink, Images} from "../customComponents";
// import { ToastContainer } from 'react-toastify';

import {useHistory} from "react-router-dom";
import MobileBreaker from "./MobileBreaker";
import {systemColor} from '../../utility/styles';

import { RiMenuLine } from "react-icons/ri";
import LoadingOverlay from 'react-loading-overlay';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';
import {authActions} from '../../redux/actions';


function Header(props) {
    
        const [uploadingAvatar, setUploadingAvatar]           = useState(false);
        const hiddenFileInput = React.useRef(null);

        const systemColorManager = systemColor()
        const dispatch = useDispatch();

        const history = useHistory();
        const userData = useSelector(userSelect);
        const site_settings = useSelector(allRemoteSettingsSelect);

        const loadHome = () => {
            // if(history.goBack.length > 0){
            //     history.goBack() ?? history.push('/')
            // }else{
            //     history.push('/')
            // }

            history.goBack()
        }


        const progClickFileUpload = () => {
            if(!userData?.image){ history.push('/signin'); return;}
            hiddenFileInput.current.click();
        }


        const uploadAvatar = async (file) => {

            // instantUploadPreview(file, 'avatarPlace');
            setUploadingAvatar(true)

            dispatch(authActions.onLogin("account/change-avatar", {avatar:file}, response => {
                    setUploadingAvatar(false)
                }),
            )
      
        }

 


        return <div className="" style={{position: 'fixed', width: '100%', zIndex: 999999999, top: 0, left: 0}}>
                    
                    <MobileBreaker>
                        
                        <div className="header-area" style={systemColorManager.header}>
                            <div className="container">
                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                    <div className="col-sm-3 col-3">
                                        <div className="menu-bar" style={systemColorManager.txt_1, {width: '50px'}}>
                                            <RiMenuLine size={20} style={systemColorManager.txt_1}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6 text-center">
                                        <WebLink to={'/'} className="logo">
                                            <Images style={{ height: '50px' }} src={(site_settings?.site_settings?.logo)} alt="logo" />
                                        </WebLink>
                                    </div>
                                    <div className="col-sm-3 col-3 text-right">
                                        <ul className="header-right">
                                        
                                            <li>
                                                <WebLink to={'/notification'}>
                                                    <i className="fa fa-bell animated infinite swing" />
                                                    <span style={{...systemColorManager.btn_2, width: 18, height: 18}} className="flexify badge">{userData?.totalNotifications ?? 0}</span>
                                                </WebLink>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>






                    </MobileBreaker>

                </div>


}

export default Header;